<template>
  <cw-page
    icon="check"
    class="instalment-loan__complete"
  >
    <template #hero-title>
      <translate>
        Thank you for choosing our service!
      </translate>
    </template>

    <template #hero-text>
      <translate>
        Welcome new customer!
      </translate>
    </template>

    <template #default>
      <v-row
        justify="center"
        class="content__wrapper"
      >
        <v-col
          cols="12"
          md="6"
        >
          <p class="mb-6">
            <translate>
              Thank you for choosing our service and welcome to Saldo!
            </translate>
          </p>
          <div class="cw-application__table">
            <table style="width: 100%;">
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td class="text-left">
                    {{ item.name }}
                  </td>
                  <td class="text-right font-weight-medium">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="mt-6">
            <translate>
              Saldo uses Trustly AB as a payment method. This means that when the loan is
              transferred to your account, the payment will be made by Trustly AB instead of Saldo.
            </translate>
          </p>
          <p class="mb-0">
            <translate>
              Invoices will be sent by post 14 days before the due date. If you want invoices by
              e-mail, please contact our customer service.
            </translate>
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <cw-review :data="initialData"/>
        </v-col>
      </v-row>
    </template>
  </cw-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwCompleteInstalmentLoan',

  components: {
    'cw-review': () => import('@shared/components/Review'),
  },

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      locale: 'application/locale',
      offer: 'application/getSelectedOffer',
    }),

    items() {
      return [{
        name: this.$gettext('Loan amount'),
        value: this.$filters.currency(this.offer.loanLimit, { locale: this.locale }),
      }, {
        name: this.$gettext('Will be paid'),
        value: this.paymentScheduleDescription,
      }, {
        name: this.$gettext('Account number'),
        value: this.initialData.bankAccount,
      }];
    },

    paymentScheduleDescription() {
      const { nightWithdrawalTime } = this.initialData;

      if (nightWithdrawalTime) {
        const formattedTime = this.$filters.date(nightWithdrawalTime, {
          type: 'time',
          locale: this.locale,
        });

        return this.$gettextInterpolate(
          this.$gettext("at the earliest %{ time } o'clock."),
          { time: formattedTime },
        );
      }

      return this.$gettext('as quickly as possible');
    },
  },
};
</script>

<style lang="scss">
  .cw-application__table--complete {
    max-width: 600px;
  }
</style>

<style lang="scss" scoped>
  .instalment-loan__complete .cw-form-loader {
    position: relative !important;
  }
</style>
